<template>
	<div class="page">
        <el-button type="primary" v-if="payment_post_time" @click="payment_post">开启自动支付</el-button>
        <el-button type="danger" v-if="!payment_post_time" @click="payment_post_close">关闭自动支付</el-button>
		<el-button type="primary" v-if="query_post_time" @click="query_post">开启自动查询</el-button>
        <el-button type="danger" v-if="!query_post_time" @click="query_post_close">关闭自动查询</el-button>
        <el-button type="primary" @click="payment_post2(1)">支付</el-button>
		<el-button type="primary" @click="payment_post2(2)">查询</el-button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                 payment_post_time:false,
                 query_post_time:false,
                 payment_timer: null,
                 query_timer:null,
			}
		},
		created() {
            //启动定时器
            this.payment_timer = setInterval(() => {
                this.payment_post();
            }, 10000);
            this.query_timer = setInterval(() => {
                this.query_post();
            }, 10000);
		},
		methods: {
            payment_post2(num){
                this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'hsbank_tord_payed_list',
                        pay:num,
                    },callback:(data)=>{
                        if(data.msg.list.length ==0){
                            return
                        }
                        let settle_target = '' //收款人类型
                        let bank_info=''  //收款人信息
                        //是否有默认银行卡
                        if(data.msg.list[0].driver_bank_list.num !=0){
                                data.msg.list[0].driver_bank_list.list.forEach(item => {
                                if(item.is_default ==1){
                                    bank_info=item
                                }
                            });
                        }
                        //判断是否收款人类型
                        if(Number(data.msg.list[0].this_settled_of_driver)>0 && data.msg.list[0].settle_status_of_driver ==2){
                            settle_target ='driver'
                           if(num ==1){
                                console.log(data.msg.list[0].payed_num,bank_info,settle_target,'支付参数')
                                this.$my.net.req({
                                    take_over_control:1,
                                    data:{
                                        mod:'truck_tord_real_time',
                                        ctr:'payed_by_hsbank',
                                        payed_num:data.msg.list[0].payed_num,
                                        bankcard:bank_info,
                                        settle_target,
                                    },callback:(data1)=>{
                                        console.log(data1,'支付',data.msg.list[0].payed_num)
                                    }
                                })
                            }else if(num ==2){
                                console.log(data.msg.list[0].payed_num,settle_target,'查询参数')
                                this.$my.net.req({
                                    take_over_control:1,
                                    data:{
                                        mod:'truck_tord_real_time',
                                        ctr:'ser_pay_result_by_hsbank',
                                        payed_num:data.msg.list[0].payed_num,
                                        settle_target,
                                    },callback:(data1)=>{
                                        console.log(data1,'查询',data.msg.list[0].payed_num)
                                    }
                                })
                            }
                            return
                        }else if(Number(data.msg.list[0].this_settled_of_truck_owner)>0 && data.msg.list[0].settle_status_of_truck_owner ==2){
                            settle_target ='truck_owner'
                            if(num ==1){
                                console.log(data.msg.list[0].payed_num,bank_info,settle_target,'支付参数')
                                this.$my.net.req({
                                    take_over_control:1,
                                    data:{
                                        mod:'truck_tord_real_time',
                                        ctr:'payed_by_hsbank',
                                        payed_num:data.msg.list[0].payed_num,
                                        bankcard:bank_info,
                                        settle_target,
                                    },callback:(data)=>{
                                        console.log(data,'支付',payed_num)
                                    }
                                })
                            }else if(num ==2){
                                console.log(data.msg.list[0].payed_num,settle_target,'查询参数')
                                this.$my.net.req({
                                    take_over_control:1,
                                    data:{
                                        mod:'truck_tord_real_time',
                                        ctr:'ser_pay_result_by_hsbank',
                                        payed_num:data.msg.list[0].payed_num,
                                        settle_target,
                                    },callback:(data)=>{
                                        console.log(data,'查询',payed_num)
                                    }
                                })
                            }
                            return
                        }
                    }
                })
            },
            //关闭自动查询
            query_post_close(){
                this.query_post_time = true
                clearInterval(this.query_timer);
                this.query_timer = null;
            },
             //关闭自动支付
            payment_post_close(){
                this.payment_post_time = true
                clearInterval(this.payment_timer);
                this.payment_timer = null;
            },
            //开启自动支付
            payment_post(){
                console.log('支付触发了')
                this.payment_post_time = false
                clearInterval(this.payment_timer);
                this.payment_timer = null;
                this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'hsbank_tord_payed_list',
                        pay:1,
                    },callback:(data)=>{
                        if(data.code !=0){
                            this.payment_timer = setInterval(() => {
                              this.payment_post();
                            }, 10000);
                            return
                        }
                        if(data.msg.list.length ==0){
                            this.payment_timer = setInterval(() => {
                              this.payment_post();
                            }, 10000);
                            return
                        }
                        let settle_target = '' //收款人类型
                        let bank_info=''  //收款人信息
                        //是否有默认银行卡
                        if(data.msg.list[0].driver_bank_list.num !=0){
                                data.msg.list[0].driver_bank_list.list.forEach(item => {
                                if(item.is_default ==1){
                                    bank_info=item
                                }
                            });
                        }
                        //判断是否收款人类型
                        if(Number(data.msg.list[0].this_settled_of_driver)>0 && data.msg.list[0].settle_status_of_driver ==2){
                            settle_target ='driver'
                            this.payment(data.msg.list[0].payed_num,bank_info,settle_target)
                            return
                        }else if(Number(data.msg.list[0].this_settled_of_truck_owner)>0 && data.msg.list[0].settle_status_of_truck_owner ==2){
                            settle_target ='truck_owner'
                            this.payment(data.msg.list[0].payed_num,bank_info,settle_target)
                            return
                        }
                    }
                })
            },
            payment(payed_num,bankcard,settle_target){
                //发起支付
                 this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'payed_by_hsbank',
                        payed_num,
                        bankcard,
                        settle_target,
                    },callback:(data)=>{
                        console.log(data,'支付',payed_num)
                        this.payment_timer = setInterval(() => {
                            this.payment_post();
                        }, 10000);
                    }
                })
            },
            //自动查询
            query_post(){
                this.query_post_time = false
                clearInterval(this.query_timer);
                this.query_timer = null;
                this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'hsbank_tord_payed_list',
                        pay:2,
                    },callback:(data)=>{
                        if(data.code !=0){
                            this.payment_timer = setInterval(() => {
                              this.payment_post();
                            }, 10000);
                            return
                        }
                        if(data.msg.list.length ==0){
                            this.query_timer = setInterval(() => {
                              this.query_post();
                            }, 10000);
                            return
                        }
                        let settle_target = '' //收款人类型
                        let bank_info=''  //收款人信息
                        //是否有默认银行卡
                        if(data.msg.list[0].driver_bank_list.num !=0){
                                data.msg.list[0].driver_bank_list.list.forEach(item => {
                                if(item.is_default ==1){
                                    bank_info=item
                                }
                            });
                        }
                        //判断是否收款人类型
                        if(Number(data.msg.list[0].this_settled_of_driver)>0 && data.msg.list[0].settle_status_of_driver ==2){
                            settle_target ='driver'
                            this.query_payment(data.msg.list[0].payed_num,bank_info,settle_target)
                            return
                        }else if(Number(data.msg.list[0].this_settled_of_truck_owner)>0 && data.msg.list[0].settle_status_of_truck_owner ==2){
                            settle_target ='truck_owner'
                            this.query_payment(data.msg.list[0].payed_num,bank_info,settle_target)
                            return
                        }
                    }
                })
            },
            query_payment(payed_num,settle_target){
                  this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'ser_pay_result_by_hsbank',
                        payed_num,
                        settle_target,
                    },callback:(data)=>{
                        console.log(data,'查询',payed_num)
                        this.query_timer = setInterval(() => {
                            this.query_post();
                        }, 10000);
                    }
                })
            }
		},
        beforeDestroy(){
            console.log('页面退出,卸载定时器')
            clearInterval(this.query_timer);
            this.query_timer = null;
            clearInterval(this.payment_timer);
            this.payment_timer = null;
        }
	}
</script>

<style lang="scss" scoped>

</style>